<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox">
      <div class="logo">
      <!-- <img
        :src="require('@/page/namvaytien/components/img/logo.png')"
        :alt="logo"
        class="logoImg"
      /> -->
      <span class="title">T.T. Blues Capital, S.A. de C.V., SOFOM, E.N.R.</span>
    </div>
    <ul class="nav-links">
      <li
        :class="{ 'active-link': isActive('/') }" class="Li1">
        <router-link to="/" class="nav-link1">
          <span class="routeText1">Producto</span>
        </router-link>
      </li>
      <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
        <router-link to="/about" class="nav-link2">
          <span class="routeText2">Sobre nosotros</span>
        </router-link>
      </li>
      <li
        :class="{ 'active-link': $route.path === '/question' }" class="Li3">
        <router-link to="/question" class="nav-link3">
          <span class="routeText3">Apoyo</span>
        </router-link>
      </li>
      <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
        <router-link to="/protocol" class="nav-link4">
          <span class="routeText4">Acuerdo de Privacidad</span>
        </router-link>
      </li>
    </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "@/page/namvaytien/components/img/Rectangle.png",
      link1Color: "white", 
    };
  },
  methods: {
   
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
  height:110px;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding-top: 10px;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 80px;
  padding: 0 15px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 28px rgba(50, 55, 71, 0.10);
}

.logo {
  font-weight: bold;
  color: black;
  display: flex; /* 让 Logo 内容成为一个 flex 容器，使图片和文字水平排列 */
  align-items: center; /* 垂直居中对齐 */
  padding-left: 10px;
}

.logoImg {
  width: 50px;
  height: 50px;
}
.title {
  width: 400px;
  height: 80px;
  font-size: 16px;
  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #000000;
  line-height: 80px;
  padding-left: 16px;
}
.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #626673;
  /* line-height: 29px; */
  text-transform: uppercase;
}



.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;

  margin: 0 auto;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  /* padding: 6px; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* height: 70px; */
  padding-left: 4px;
  padding-right: 4px;
  line-height: 80px;
}
.Li1 {
  width: 150px;
}
.Li2 {
  width: 200px;
}
.Li3 {
  width: 110px;
}
.Li4 {
  width: 270px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}
.Li5 {
  width: 210px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}

.nav-links li {
  /* padding: 5px; */
  background-size: 100% 63%;
  background-repeat: no-repeat;
  height: 80px;
}
.active-link {
  background: url("@/page/ttbluescapital/components/img/Group1.png");
  /* background-color: #EBF7FF; */
  background-size: 100% 100%; /* 背景图片充满整个容器 */
  background-repeat: no-repeat;
  background-position: 0 13px;
}
.active-link .routeText {
  color: white;
}
.active-link span{
  color: #FFFFFF;
}
.secondBox {
  border-radius: 70px;
  box-shadow: 0 3px 15px rgba(124, 25, 11, 0.25);
  max-width: 1400px;
}
.navbar{
  background: #FFFFFF;
  padding: 0 70px;
}
</style>